// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
var countUpStatsDuration = 3000;

var subsiteHijackMainNav = ['ageingbetter' , 'communitydevelopment' , 'bvsc-charity-jobs-wm', 'bvsc-menopause-support-in-and-out-of-the-workplace' , 'brumenergy'];
var subsiteHijackMainLogoClickDestination = ['ageingbetter' , 'communitydevelopment' , 'bvsc-charity-jobs-wm' , 'bvsc-menopause-support-in-and-out-of-the-workplace' , 'brumenergy'];

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;